/* .sidebar {
  width: 20%;
}

.main-view {
  width: 80%;
} */

.tree-item button {
  border: none;
  padding: 0;
  background: #ffffff; 
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
}