.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;
  background-color:rgb(226, 230, 237);
  margin: 0px;
  padding: 0px;
  }

  .googleButton {
    height: 50px;
    margin: 10px 20px 0px auto;
    transition: .3s;
  }

  .googleLogo {
    height: 100%;
  }

  .googleButton:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
