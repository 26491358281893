* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.highlight-color {
  background-color: #EAEEF5;
}

.project-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4%;
  margin: auto;
}

.single-project-component {
  background-color: #ffff;
  width: 350px;
  height: 100%;
  margin: 30px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #bfbfbf;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.title-row {
  display: flex;
  justify-content: space-between;
}

.singleProject-h6 {
  margin: 3px 0px 5px 5px;
}

.valutree-row {
  padding: 10px;
}

.project-page-container .add-icon {
  position: fixed;
  bottom: 0;
  background-color: #bfbfbf;
  align-self: flex-end;
  width: 4%;
  display: flex;
  margin: 0 0 2% 0;
  height: 6%;
  border-radius: 50%;
}