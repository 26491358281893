body {
  margin: 0;
  font-family: 'Helvetica Neue','Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: 'rgb(61, 64, 67)';
  display: 'block';
  text-decoration: 'none';
  font-size: '1.2rem'
}

.svgContainer {
  position: relative;
  height: 100%;
  margin: 1% 0 1% 8%;
  padding: 2px 2px;
  box-sizing: border-box;
}
