
.create-new-project-modal {
  position: absolute;
  top: 60%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 45%;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 1%;
  padding: 2%
};
